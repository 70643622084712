import React, {FC} from 'react'
import {ErrorMessage, Field, FormikProps} from 'formik'
import {IDatasource} from '../../../core/IDataSourceModels'


interface Step2Props {
  formik: FormikProps<IDatasource> 
}

const Step2: FC<Step2Props> = ({formik}) => {
  const databaseRender = formik.values.database

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Configuration Info</h2>
        <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out
          <a href='/dashboard' className='link-primary fw-bolder'>
            {' '}
            Help Page
          </a>
          .
        </div>
      </div>

      <div className='notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6'>
        <i className='ki-outline ki-information fs-2tx text-primary me-4' />
        <div className='d-flex flex-stack flex-grow-1'>
          <div className='fw-semibold'>
            <h4 className='text-gray-900 fw-bold'>
              Configure{' '}
              {databaseRender === 'MONGO'
                ? 'MongoDB'
                : databaseRender === 'FIREBASE'
                ? 'Firebase'
                : databaseRender === 'MYSQL'
                ? 'MySql'
                : 'Postgres'}{' '}
            </h4>
            <div className='fs-6 text-gray-700'>
              Please configure your{' '}
              {databaseRender === 'MONGO'
                ? 'MongoDB'
                : databaseRender === 'FIREBASE'
                ? 'Firebase'
                : databaseRender === 'MYSQL'
                ? 'MySql'
                : 'Postgres'}
              <a
                target='_blank'
                href={
                  databaseRender === 'MONGO'
                    ? 'https://account.mongodb.com/account/register'
                    : databaseRender === 'FIREBASE'
                    ? 'https://console.firebase.google.com/'
                    : databaseRender === 'MYSQL'
                    ? 'https://www.mysql.com/'
                    : 'https://www.postgresql.org/'
                }
              >
                {' '}
                database here
              </a>
            </div>
          </div>
        </div>
      </div>

      {databaseRender === 'MYSQL' && (
        <div className='row fv-row'>
          <div className='fv-row col-6 mb-10'>
            <label className='form-label required'>Username</label>
            <Field name='userName' className='form-control form-control-lg form-control-solid' />
            <div className='text-danger mt-2'>
              <ErrorMessage name='userName' />
            </div>
          </div>
          <div className='fv-row col-6 mb-10'>
            <label className='form-label required'>Password</label>
            <Field name='password' className='form-control form-control-lg form-control-solid' />
            <div className='text-danger mt-2'>
              <ErrorMessage name='password' />
            </div>
          </div>
        </div>
      )}

      {databaseRender === 'MONGO' && (
        <div className='fv-row mb-10'>
          <label className='form-label required'>Database Url</label>
          <Field name='url' className='form-control form-control-lg form-control-solid' />
          <div className='text-danger mt-2'>
            <ErrorMessage name='url' />
          </div>
        </div>
      )}

      <div className='fv-row mb-10'>
        <label className='form-label required'>Location Type</label>
        <Field as='select' name='located' className='form-select form-select-solid'>
          <option value='CLOUD'>Cloud</option>
        </Field>
        <div className='text-danger mt-2'>
          <ErrorMessage name='located' />
        </div>
      </div>

      {/* Host:Port field only shows when MySQL is selected */}
      {databaseRender === 'MYSQL' && (
        <div className='fv-row mt-4'>
          <label className='form-label required'>Host:Port</label>
          <Field
            type='text'
            name='hostPort'
            className='form-control form-control-lg form-control-solid'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='hostPort' />
          </div>
        </div>
      )}
    </div>
  )
}

export {Step2}
