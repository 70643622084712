import React, {FC} from 'react'
import {Field, ErrorMessage} from 'formik'
import {Link} from 'react-router-dom'
import {KTIcon, useIllustrationsPath} from '../../../../../../../_metronic/helpers'

const Step3: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-5 pb-lg-5'>
        <h2 className='fw-bolder text-dark text-center'>Your Are Done!</h2>

        {/* <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please
          <Link to='/auth/login' className='link-primary fw-bolder'>
            {' '}
            Sign In
          </Link>
          .
        </div> */}
      </div>

      <div className='mb-0'>
        <div className='fs-6 text-gray-600 text-center'>
          The data source oversees the management of databases within your application, and you are
          welcome to utilize any of the databases available in your workspace's applications.
        </div>


        <div className='text-center px-2 '>
          <img src={useIllustrationsPath('5.png')} alt='' className='mw-100 mh-350px' />
        </div>
      </div>
    </div>
  )
}

export {Step3}
